// https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
export const extractInitialsFromName = (name: string): string => {
  const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  const matches = [...name.matchAll(initialsRegex)] || [];

  const initials = ((matches.shift()?.[1] || '') + (matches.pop()?.[1] || '')).toUpperCase();

  return initials;
};

export const getUserFullName = <T extends { firstName?: string; lastName?: string }>(user?: T): string => {
  let output = '';

  if (user?.firstName) {
    output = `${output}${user.firstName}`;

    if (user?.lastName) {
      output = `${output} `;
    }
  }

  if (user?.lastName) {
    output = `${output}${user.lastName}`;
  }

  return output;
};

export const extractInitialsFromNames = <T extends { firstName?: string; lastName?: string }>(user?: T): string => {
  if (!user) {
    return '';
  }

  const firstName = user?.firstName?.length ? user.firstName : '';
  const lastName = user?.lastName?.length ? user.lastName : '';

  if (!firstName && !lastName) {
    return '';
  }

  if (firstName.length && !lastName.length) {
    return `${firstName.charAt(0)}${firstName.charAt(1) ?? ''}`.toLocaleUpperCase();
  }

  if (!firstName.length && lastName.length) {
    return `${lastName.charAt(0)}${lastName.charAt(1) ?? ''}`.toLocaleUpperCase();
  }

  return `${firstName.charAt(0)}${lastName.charAt(0)}`.toLocaleUpperCase();
};
