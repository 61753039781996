export function invariant(condition: any, message?: string | (() => string)): asserts condition {
  if (condition) {
    return;
  }

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  const prefix: string = 'Invariant failed';

  const value: string = provided ? `${prefix}: ${provided}` : prefix;
  throw new Error(value);
}
