/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString(date: Date | number, lang = 'en-GB'): string {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units
  const units: Intl.RelativeTimeFormatUnit[] = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds));

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const formatter = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });

  return formatter.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

export function getRelativeAgeString(ageMs: number | undefined): string {
  if (!ageMs) {
    return '-';
  }

  const secondMs = 1000;
  const minuteMs = secondMs * 60;
  const hourMs = minuteMs * 60;
  const dayMs = hourMs * 24;

  if (ageMs < secondMs) {
    return `${(ageMs / secondMs).toFixed(1)}s`;
  }

  if (ageMs < minuteMs) {
    return `${(ageMs / minuteMs).toFixed(1)}m`;
  }

  if (ageMs < hourMs) {
    return `${(ageMs / hourMs).toFixed(1)}h`;
  }

  return `${(ageMs / dayMs).toFixed(1)}d`;
}

export const adjustUTCDateToLocalTimezone = (date: Date): Date => {
  // TODO: Remove this fn.
  return date;
};

export const getFormattedDateAndTimeString = (date: Date): string => {
  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(new Date(date as unknown as string));

  return formattedDate;
};

export const getFormattedDateString = (date: Date): string => {
  const enquiryDate = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
  }).format(date);

  return enquiryDate;
};
